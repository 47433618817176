import type { NuxtAxiosInstance } from '@nuxtjs/axios'
import type { Context } from '@nuxt/types'
import {
  ThreadPathParams,
  ContactIdsResult,
  ContactParam,
  ElasticSearchResult,
  InboxMeta,
  SearchResponse,
  Thread,
  ThreadFilter,
  SearchSuggestions
} from '~/services/types/Thread'
import useTag from '~/composables/useTag'
const THREAD_PAGE_SIZE = 30
export const INCLUDE_FIELD: Record<string, boolean> = {
  'contact.name': true,
  'contact.phone': true,
  'contact.tags': true,
  'message.body': true,
  'message.translated_body': false
}

interface IThreadService {
    $axios: NuxtAxiosInstance

    getInboxMeta: (tenantId: string, phoneNumberId: string) => Promise<InboxMeta>
    getContactIdsByFilter: (tenantId: string, phoneNumberId: string, filter: ThreadFilter, includeField: typeof INCLUDE_FIELD, translationsEnabled: boolean) => Promise<ContactIdsResult>
    getThreadsByFilter: (tenantId: string, phoneNumberId: string, filter: ThreadFilter, existingAmount: number) => Promise<SearchResponse<Thread>>
    getThreads: (tenantId: string, phoneNumberId: string, existingAmount: number, params: Record<string, any>, messageType: string, amount: typeof THREAD_PAGE_SIZE) => Promise<SearchResponse<Thread>>
    getUnreadThreads: (tenantId: string, phoneNumberId: string) => Promise<SearchResponse<Thread>>
    getThreadInformation: ({ tenantId, phoneNumberId, threadId }: ThreadPathParams) => Promise<Thread>
    markAsStarred: ({ tenantId, phoneNumberId, threadId }: ThreadPathParams, isStarred: boolean) => Promise<Thread>
    markAsRead: ({ tenantId, phoneNumberId, threadId }: ThreadPathParams, isUnread: boolean) => Promise<Thread>
    subscribeToThread: ({ tenantId, phoneNumberId, threadId }: ThreadPathParams) => Promise<Thread>
    getPDF: ({ tenantId, phoneNumberId, threadId }: ThreadPathParams) => Promise<Blob>
    getPDFFromHash: (hash: string) => Promise<Blob>
    create: (tenantId: string, phoneNumberId: string, contact: ContactParam) => Promise<any>
    getAdvancedSearchResults: (tenantId: string, phoneNumberId: string, parameters: {
      query: string
      page_size: number
      page: number
      date_sent_from: string
      date_sent_to: string
      direction: string
    }, fields: Array<string>) => Promise<SearchResponse<ElasticSearchResult>>
    getSearchSuggestions (tenantId: string, phoneNumberId: string, filter: ThreadFilter, translationsEnabled: boolean): Promise<{ threads: Array<Thread>, contacts: Array<ElasticSearchResult> } | undefined>
}

export default class ThreadService implements IThreadService {
  $axios: NuxtAxiosInstance

  constructor (context: Context) {
    this.$axios = context.$axios
  }

  getInboxMeta (tenantId: string, phoneNumberId: string) {
    return this.$axios.$get<InboxMeta>(`/api/threads/${tenantId}/${phoneNumberId}/inbox-meta`)
  }

  getTagFiltersWithPrefixes (tagFilter: string) {
    const { SPECIAL_PREFIXES } = useTag()
    const tagFilterWithoutPrefix = SPECIAL_PREFIXES
      .reduce((tagFilterWithoutPrefix: string, prefix: {prefix: string}) =>
        tagFilterWithoutPrefix.replace(prefix.prefix, ''), tagFilter)
    const specialPrefixes = SPECIAL_PREFIXES.map((prefix: {prefix: string}) => prefix.prefix)
    const getSearchableTag = (tag: string) => `(tags:"${tag}")`
    return [
      tagFilterWithoutPrefix,
      ...specialPrefixes.map((specialPrefix: string) => `${specialPrefix}${tagFilterWithoutPrefix}`)
    ].map(getSearchableTag).join(' OR ')
  }

  async getContactIdsByFilter (tenantId: string, phoneNumberId: string, filter: ThreadFilter, includeField: Record<string, boolean> = INCLUDE_FIELD, translationsEnabled = false) {
    includeField['message.translated_body'] = translationsEnabled
    const tags2SearchWithPrefixes = filter.threadTags2Filter
      ?.map(this.getTagFiltersWithPrefixes)
      .flat() || []
    const queryArr = tags2SearchWithPrefixes
    if (filter.threadText2Filter) {
      queryArr.push(filter.threadText2Filter)
    }
    const params = new URLSearchParams()
    const queryStr = queryArr.map(queryParam => `(${queryParam})`).join(' AND ')
    params.append('query', queryStr)
    params.append('page_size', (1000).toString())
    Object.entries(includeField).forEach(([field, include]) => {
      if (include) { params.append('fields', field) }
    })
    const contacts = await this.$axios.$get<SearchResponse<ElasticSearchResult>>(`/api/search/${tenantId}/${phoneNumberId}`, { params })
    const missingIds = new Set(contacts.results.map(hit => hit.type === 'contact' ? hit.id : hit.contact_id))
    return { missingIds: Array.from(missingIds), contacts }
  }

  async getThreadsByFilter (tenantId: string, phoneNumberId: string, filter: ThreadFilter, existingAmount: number) {
    const MAX_CONTACTS_TO_QUERY = 100
    const { missingIds, contacts } = await this.getContactIdsByFilter(tenantId, phoneNumberId, filter)
    if (missingIds.length) {
      return await this.getThreads(tenantId, phoneNumberId, existingAmount, {
        contact__in: missingIds.slice(0, MAX_CONTACTS_TO_QUERY).join(',')
      }, filter.messageType).then(result => ({
        ...result,
        contacts: contacts.results
      }))
    } else {
      return {
        results: [],
        contacts: contacts.results
      }
    }
  }

  async getThreads (tenantId: string, phoneNumberId: string, existingAmount: number, params: any, messageType: string, amount = THREAD_PAGE_SIZE) {
    const filtersByUnreadImportant:Record<string, number> = {}
    if (messageType === 'unread') {
      filtersByUnreadImportant.unread = 1
    } else if (messageType === 'important') {
      filtersByUnreadImportant.starred = 1
    }
    filtersByUnreadImportant.is_archived = messageType === 'archived' ? 1 : 0
    const page = Math.floor((existingAmount + 1) / THREAD_PAGE_SIZE) + 1
    const requestedPage = await this.$axios
      .$get(`/api/threads/${tenantId}/${phoneNumberId}`, {
        params: { page_size: amount, page, ...params, ...filtersByUnreadImportant }
      })
    // We want to always get a few elements from next page due to a backend issue
    const nextPageAmount = Math.floor(amount / 2)
    const nextPageNumber = page * 2 + 1
    const hasMoreRegisters = requestedPage.count > nextPageAmount * (nextPageNumber - 1) && nextPageAmount
    if (!hasMoreRegisters) {
      return requestedPage
    }
    const halfNextPage = await this.$axios
      .$get(`/api/threads/${tenantId}/${phoneNumberId}`, {
        params: { page_size: nextPageAmount, page: nextPageNumber, ...params, ...filtersByUnreadImportant }
      })
    return {
      ...requestedPage,
      results: [...requestedPage.results, ...halfNextPage.results || []]
    }
  }

  async getUnreadThreads (tenantId: string, phoneNumberId: string) {
    return await this.$axios
      .$get(`/api/threads/${tenantId}/${phoneNumberId}`, {
        params: { page_size: 0, page: 1, unread: 1 }
      })
  }

  getThreadInformation ({ tenantId, phoneNumberId, threadId }: ThreadPathParams) {
    return this.$axios.$get<Thread>(`/api/threads/${tenantId}/${phoneNumberId}/${threadId}`)
  }

  markAsStarred ({ tenantId, phoneNumberId, threadId }: ThreadPathParams, isStarred: boolean) {
    return this.$axios.$patch<Thread>(`/api/threads/${tenantId}/${phoneNumberId}/${threadId}`, {
      id: threadId,
      is_starred: isStarred
    })
  }

  markAsRead ({ tenantId, phoneNumberId, threadId }: ThreadPathParams, isUnread: boolean) {
    if (isUnread) {
      return this.$axios.$patch<Thread>(`/api/threads/${tenantId}/${phoneNumberId}/${threadId}`, {
        id: threadId,
        is_unread: isUnread
      })
    } else {
      return this.$axios.$post<Thread>(`/api/threads/${tenantId}/${phoneNumberId}/${threadId}/set-read`)
    }
  }

  subscribeToThread ({ tenantId, phoneNumberId, threadId }: ThreadPathParams) {
    return this.$axios.$get<Thread>(`/api/threads/${tenantId}/${phoneNumberId}/${threadId}`)
  }

  getPDF ({ tenantId, phoneNumberId, threadId }: ThreadPathParams) {
    return this.$axios.$get<Blob>(
      `/api/threads/${tenantId}/${phoneNumberId}/${threadId}/pdf-transcript`,
      { responseType: 'blob' })
  }

  getPDFFromHash (hash: string) {
    return this.$axios.$get<Blob>(`api/transcript/${hash}`, { responseType: 'blob' })
  }

  create (tenantId: string, phoneNumberId: string, contact: ContactParam) {
    if (!contact.email) {
      delete contact.email
    }
    return this.$axios.$post(`/api/threads/${tenantId}/${phoneNumberId}/new`, contact)
  }

  async getAdvancedSearchResults (tenantId: string, phoneNumberId: string, parameters: {
    query: string
    page_size: number
    page: number
    date_sent_from: string
    date_sent_to: string
    direction: string
  }, fields: Array<string>) {
    const urlSearchParams: any = {
      ...parameters,
      query: parameters.query
    }
    const params = new URLSearchParams(urlSearchParams)
    fields.forEach(field => params.append('fields', field))
    const searchResponse = await this.$axios.$get<SearchResponse<ElasticSearchResult>>(`/api/search/${tenantId}/${phoneNumberId}`, {
      params
    })

    if (fields.includes('contact.tags')) {
      const tagsQuery = this.getTagFiltersWithPrefixes(parameters.query)
      const tagResults = await this.$axios.$get<SearchResponse<ElasticSearchResult>>(`/api/search/${tenantId}/${phoneNumberId}`, {
        params: {
          query: tagsQuery,
          fields: 'contact.tags'
        }
      })
      const existingResultIDs = new Set(searchResponse.results.map(result => result.id))
      searchResponse.results = searchResponse.results.concat(tagResults.results.filter(result => !existingResultIDs.has(result.id)))
    }
    return searchResponse
  }

  async getSearchSuggestions (tenantId: string, phoneNumberId: string, filter: ThreadFilter, translationsEnabled: boolean) {
    const suggestions : SearchSuggestions = {
      threads: [],
      contacts: []
    }
    const MAX_CONTACTS_TO_QUERY = 10
    const { missingIds, contacts } = await this.getContactIdsByFilter(tenantId, phoneNumberId, filter, INCLUDE_FIELD, translationsEnabled)
    suggestions.contacts = contacts.results.filter(contact => contact.type === 'contact')
    if (missingIds.length) {
      const filtersByUnreadImportant : Record<string, number> = {}
      if (filter.messageType === 'unread') {
        filtersByUnreadImportant.unread = 1
      } else if (filter.messageType === 'important') {
        filtersByUnreadImportant.starred = 1
      }
      filtersByUnreadImportant.is_archived = filter.messageType === 'archived' ? 1 : 0
      const params = { contact__in: missingIds.slice(0, MAX_CONTACTS_TO_QUERY).join(',') }
      const requestedPage = await this.$axios
        .$get(`/api/threads/${tenantId}/${phoneNumberId}`, {
          params: { page_size: THREAD_PAGE_SIZE, page: 1, ...params, ...filtersByUnreadImportant }
        })
      suggestions.threads = requestedPage.results
    }
    return suggestions
  }

  getPageSize () {
    return THREAD_PAGE_SIZE
  }
}
